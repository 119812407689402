import { PostStateRequestBody } from '@iwoca/lapi-client/edge';
import Cookies from 'js-cookie';

import { getRuntimeEnvironment } from './getRuntimeEnvironment';

const BUYER_REGISTRATION_API_TOKEN =
  'Token iwoca-2ay95jmavm6gw12hf20iq97oc1bb5okti3c';

export const lendingApiFetchJson = (url: string, options = {}, headers = {}) =>
  fetch(url, {
    credentials: 'same-origin',
    ...options,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: BUYER_REGISTRATION_API_TOKEN,
      ...headers,
    },
  });

export function postLendingApiJson<T>({
  url,
  body,
  headers,
}: {
  url: string;
  body?: T;
  headers?: HeadersInit;
}) {
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'x-csrftoken': Cookies.get('csrftoken') as string,
      ...headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  });
}

class LapiError extends Error {
  statusCode: number;

  constructor(statusCode: number, rawResponse: string) {
    super(`${statusCode}: ${rawResponse}`);
    this.name = 'LapiError';
    this.statusCode = statusCode;
  }
}

const PRODUCTION_SELLER_REGISTRATION_TOKEN =
  'e9efe6f1d9896091450e4c7ef47a65d148296abd';
const STAGING_SELLER_REGISTRATION_TOKEN =
  '11fdcce04f91c0d8d1d16f82ba2a34c10f8554ec';

export const fetchPostCustomerState = async ({
  body,
}: {
  body: PostStateRequestBody;
}) => {
  const API_AUTH_TOKEN =
    getRuntimeEnvironment() === 'production'
      ? PRODUCTION_SELLER_REGISTRATION_TOKEN
      : STAGING_SELLER_REGISTRATION_TOKEN;

  const res = await postLendingApiJson({
    url: `/api/lending/edge/state/`,
    body,
    headers: {
      authorization: `Bearer ${API_AUTH_TOKEN}`,
    },
  });

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};
