import Cookies from 'js-cookie';
import queryString from 'query-string';

export const postToHubspot = async (email: string) => {
  const HUBSPOT_FORM_URL =
    'https://api.hsforms.com/submissions/v3/integration/submit/6334445/0456b719-93db-4ac3-a393-2b968274c505';
  const utmParams = getUtmParams();
  const fields = [
    {
      name: 'email',
      value: email,
    },
    ...utmParams,
  ];

  const response = await fetch(HUBSPOT_FORM_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({
      fields: fields,
      context: {
        hutk: Cookies.get('hubspotutk'),
      },
    }),
  });

  return response;
};

function getUtmParams() {
  if (!window) return [];
  const queryParams = queryString.parse(window.location.search);

  const validUtmParams = [
    'utm_campaign',
    'utm_source',
    'utm_term',
    'utm_content',
    'utm_medium',
  ];

  let utmParams: { name: string; value: string | (string | null)[] }[] = [];

  validUtmParams.forEach((param) => {
    const paramValue = queryParams[param];

    if (paramValue) {
      utmParams.push({
        name: param,
        value: paramValue,
      });
    }
  });

  return utmParams;
}
