import { IwocapaySignup } from './IwocapaySignup/IwocapaySignup';
import { IwQueryClientProvider } from './IwQueryClientProvider';

function App(): JSX.Element {
  return (
    <IwQueryClientProvider>
      <IwocapaySignup />
    </IwQueryClientProvider>
  );
}

export default App;
