import { useState } from 'react';

import { Tracking } from '@iwoca/frontend-tracking-library';
import { v4 as uuidv4 } from 'uuid';

import useQueryUrl from './useQueryUrl';
import { FormData } from '../IwocapaySignup/IwocapaySignup';
import { fetchPostCustomerState } from '../utils/lendingApi';
import logger from '../utils/logger';
import { postToHubspot } from '../utils/postToHubspot';

export const useSubmitForm = () => {
  const [signupError, setSignupError] = useState<boolean>(false);
  const { queryUrl } = useQueryUrl('/pay/activation/');

  const submitForm = async (data: FormData) => {
    setSignupError(false);

    await fetchPostCustomerState({
      body: {
        data: {
          application: {
            people: [
              {
                emails: [
                  {
                    email: data.emailAddress,
                    marketing_opt_in: { agreed: data.marketingOptIn },
                    uid: uuidv4(),
                    type: 'primary',
                  },
                ],
                uid: uuidv4(),
                roles: ['applicant'],
              },
            ],
          },
        },
      },
    })
      .then(async () => {
        Tracking.trackEvent({
          event: 'Seller signup submitted success',
          label: 'Landing page',
        });
        await postToHubspot(data.emailAddress);
        window.location.href = queryUrl;
      })
      .catch(() => {
        logger.error('iwocaPay signup failed');
        setSignupError(true);
      });
  };
  return { submitForm, signupError };
};
