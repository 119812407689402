import { GetEmailAvailabilityResponse } from '@iwoca/lapi-client/edge';
import { memoize } from 'lodash';

import { useDebounce } from './useDebounce';
import { lendingApiFetchJson } from '../utils/lendingApi';

const getEmailAvailability = async (emailAddress: string): Promise<boolean> => {
  const encodedEmailAddress = encodeURIComponent(emailAddress);
  const apiResponse = await lendingApiFetchJson(
    `/api/lending/edge/email_availability/?email=${encodedEmailAddress}`
  );

  const responsePayload: GetEmailAvailabilityResponse =
    await apiResponse.json();

  return responsePayload.data.available;
};

const memoizedEmailCheck = memoize(getEmailAvailability);

export function useEmailAvailability() {
  const debounceEmailAvailability = useDebounce(memoizedEmailCheck);
  const emailAvailability = async (email: string) => {
    let isEmailAvailable: null | boolean = null;
    try {
      isEmailAvailable = (await debounceEmailAvailability(email)) as boolean;
    } catch (e: unknown) {}

    return isEmailAvailable;
  };

  return emailAvailability;
}
