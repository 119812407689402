import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Tracking } from '@iwoca/frontend-tracking-library';
import { Button, Input } from '@iwoca/orion';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import styles from './IwocapaySignup.module.css';
import { ErrorIcon } from '../assets/ErrorIcon';
import { LoadingSpinner } from '../assets/LoadingSpinner/LoadingSpinner';
import { useEmailAvailability } from '../hooks/useEmailAvailability';
import { useGetContext } from '../hooks/useGetContext';
import useQueryUrl from '../hooks/useQueryUrl';
import { useSubmitForm } from '../hooks/useSubmitForm';

Tracking.initialize({
  customerJourney: 'Website',
  trackWithDataAttribute: true,
});

const validationSchema = yup.object({
  emailAddress: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  marketingOptIn: yup.boolean(),
});

export type FormData = yup.InferType<typeof validationSchema>;

export const IwocapaySignup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(validationSchema) });

  const { submitForm, signupError } = useSubmitForm();
  const isEmailAvailable = useEmailAvailability();
  const [emailIsAvailable, setEmailIsAvailable] = useState<boolean | null>(
    null
  );
  const {
    context: { isLoading, isLoggedIn },
  } = useGetContext();
  const { queryUrl: loggedInQueryUrl } = useQueryUrl('/pay/activation/');

  if (isLoading) return null;

  if (isLoggedIn)
    return (
      <Button className={styles.loggedInButton} href={loggedInQueryUrl}>
        Go to my dashboard
      </Button>
    );

  return (
    <form
      className={styles.formContainer}
      onSubmit={handleSubmit(async (data) => {
        const isAvailable = await isEmailAvailable(data.emailAddress);
        setEmailIsAvailable(isAvailable);
        if (isAvailable !== false) {
          await submitForm(data);
        }
      })}
    >
      <div>
        <Input
          className={styles.emailInput}
          placeholder="Your business email"
          {...register('emailAddress', {
            onChange: async (e) => {
              const isAvailable = await isEmailAvailable(e.target.value);
              setEmailIsAvailable(isAvailable);
            },
          })}
        />
        {errors.emailAddress && (
          <div className={styles.errorMessage}>
            <ErrorIcon />
            <p className={styles.errorText}>{errors.emailAddress.message}</p>
          </div>
        )}
        {emailIsAvailable === false && (
          <div className={styles.errorMessage}>
            <ErrorIcon />
            <p className={styles.errorText}>
              Looks like you already have an account.{' '}
              <a
                href="/login/?redirect_to=/pay/activation/"
                className={styles.link}
              >
                Click here
              </a>{' '}
              to log in
            </p>
          </div>
        )}
        {signupError && (
          <div className={styles.errorMessage}>
            <ErrorIcon />
            <p className={styles.errorText}>
              Something went wrong, please try again.
            </p>
          </div>
        )}
      </div>
      <Button
        className={styles.submitButton}
        disabled={isSubmitting || emailIsAvailable === false}
        type="submit"
        data-ga-id="aboveTheFold___iwocapaycta"
      >
        {isSubmitting ? (
          <>
            <div className={styles.isSubmittingText}>Loading...</div>
            <LoadingSpinner />
          </>
        ) : (
          'Get access'
        )}
      </Button>
      <div className={styles.checkboxContainer}>
        <Input type="checkbox" {...register('marketingOptIn')} />
        <div>Get updates about iwocaPay</div>
      </div>
    </form>
  );
};
