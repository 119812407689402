import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const IwQueryClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              staleTime: Infinity,
              refetchInterval: false,
              refetchOnWindowFocus: false,
              refetchOnReconnect: false,
              refetchOnMount: false,
              retry: false,
              retryOnMount: false,
            },
          },
          // Following the config for testing in here:
          // https://tanstack.com/query/v4/docs/react/guides/testing#turn-off-network-error-logging
          logger: {
            log: console.log,
            warn: console.warn,
            error: process.env.NODE_ENV === 'test' ? () => {} : console.error,
          },
        })
      }
    >
      {children}
    </QueryClientProvider>
  );
};
