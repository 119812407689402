import { useEffect, useState } from 'react';

const constructUrl = (url: string) => {
  if (!window.location.search) {
    return url;
  }

  const isUrlAnchor = url.startsWith('#');
  if (isUrlAnchor) {
    return `${window.location.search}${url}`;
  }
  return `${url}${window.location.search}`;
};

const useQueryUrl = (url: string) => {
  const [queryUrl, setEligibilityUrl] = useState<string>(url);

  useEffect(() => {
    setEligibilityUrl(constructUrl(url));
  }, [url]);

  return { queryUrl };
};

export default useQueryUrl;
