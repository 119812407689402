import { fetchGetContext } from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';

interface AuthContextType {
  stateKey: string | undefined | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  isSeller: boolean;
  user_signup_finished: boolean | null;
  isOnSoleTraderPilot: boolean;
}

export function useGetContext() {
  const { data, isLoading, isError, refetch, error } = useQuery({
    queryKey: ['fetchGetContext'],
    queryFn: () => fetchGetContext({}),
  });

  const contextData: AuthContextType = {
    stateKey: data?.data?.state_key as AuthContextType['stateKey'],
    isLoading: isLoading,
    // @ts-expect-error
    isSeller: data?.data?.is_iwocapay_seller,
    isLoggedIn: !!data && !isError,
    user_signup_finished: data?.data?.user_signup_finished || null,
    isOnSoleTraderPilot: data?.data?.is_iwocapay_st_pilot || false,
  };

  return {
    context: contextData,
    getContext: refetch,
    loadingContext: isLoading,
    contextError: error,
  };
}
